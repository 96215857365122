<div class="label-section">
    <div [style.width]="'150px'" class="text-center py-2">METRIC VALUE</div>
    <div [style.width]="'150px'" class="text-center py-2">SKU PLAN</div>
</div>
<nz-table
    #basicTable
    [nzTemplateMode]="true"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzBordered]="false"
    [nzLoading]="loading"
    class="options-monthly-bottom"
>
    <thead>
        <tr>
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-bottom__total py-2">
                {{ data?.rows[0].product_level_value }}
            </th>
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-bottom__total py-2">
                {{ data?.rows[0].sku_plans.sku_plan }}
            </th>
            <th
                class="options-monthly-bottom__total py-2 pr-3"
                *ngFor="let column of data?.columns"
                [nzAlign]="'center'"
                [nzWidth]="'150px'"
            >
                {{ data?.rows[0].sku_plans[column.column_name] }}
            </th>
            <th [nzAlign]="'center'" [nzWidth]="'150px'" class="options-monthly-bottom__total py-2 pr-3">
                {{ data?.rows[0].sku_plans.total }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr class="options-monthly__row" *ngFor="let row of data?.rows | slice : 1; index as i">
            <td [nzAlign]="'center'" class="options-monthly-bottom__row-header">
                {{ row.product_level_value }}
            </td>
            <td [nzAlign]="'center'" class="options-monthly-bottom__td">
                {{ row.sku_plans.sku_plan }}
            </td>
            <td
                class="options-monthly-bottom__input-cell"
                *ngFor="let column of data?.columns"
                [nzAlign]="'center'"
                (click)="startEdit(column.column_name, i)"
            >
                <div class="editable-cell" *ngIf="editId?.[column.column_name] !== i">
                    {{ row.sku_plans[column.column_name] }}
                </div>
                <input
                    type="text"
                    nz-input
                    lynkdPatternAutoFocus
                    *ngIf="editId?.[column.column_name] === i"
                    [(ngModel)]="row.sku_plans[column.column_name]"
                    (keydown.enter)="stopEdit()"
                    (blur)="stopEdit()"
                />
            </td>
            <td
                [nzAlign]="'center'"
                class="options-monthly-bottom__total"
                [class.invalid]="checkSkuPlanTotalMismatch(row.sku_plans.sku_plan, row.sku_plans['Total'])"
            >
                {{ row.sku_plans['Total'] }}
            </td>
        </tr>
    </tbody>
</nz-table>
