import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { firstValueFrom } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { IAppState, selectAnalyticsActiveRoute } from '../../core/core.state';
import { LoadingSpinnerService } from '../../core/data/loading-spinner.service';
import { ICurrentBoard } from '../../core/models/current-board.model';
import { DisplayModelLabel } from '../../core/models/display-model-label.enum';
import { BoardService } from '../../shared/services/board.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'lynkd-pattern-dialog-board-save',
    templateUrl: './dialog-board-save.component.html'
})
export class DialogBoardSaveComponent implements OnInit, OnDestroy {
    public boardData: ICurrentBoard = {
        user_id: '',
        board_id: 0,
        board_name: '',
        time_period: [],
        time_range: [],
        time_year: [],
        time_quarter: [],
        time_month: [],
        location_company: [],
        location_division: [],
        location_area: [],
        location_region: [],
        location_store: [],
        sort_order: '',
        department_name: [],
        sub_department_name: [],
        category_name: [],
        product_type_name: [],
        filter_time: '',
        filter_product: '',
        filter_meta: ''
    };
    public boardId: number = 0;
    public boardName: string = '';
    public timeStatic: boolean = false;
    public saveFirst: boolean;
    public isPatternBoard: boolean;
    public useBrandivioBranding: boolean = environment.useBrandivioBranding;
    private _active: boolean = true;
    private _view: DisplayModelLabel;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: { boardData: ICurrentBoard; saveFirst: boolean },
        public dialogRef: MatDialogRef<DialogBoardSaveComponent>,
        private readonly _boardService: BoardService,
        private readonly _loadingSpinner: LoadingSpinnerService,
        private readonly _snackBar: MatSnackBar,
        private readonly _store: Store<IAppState>
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this._store.select(selectAnalyticsActiveRoute).pipe(takeWhile(() => this._active)).subscribe((view: DisplayModelLabel) => {
            this._view = view;
        });
        this.boardData = this.data.boardData;

        // if pattern boards, create board from scratch
        if (this.boardData.type === 'PATTERN_BOARDS' || this.boardData.user_id === 'PATTERN_BOARDS') {
            this.isPatternBoard = true;
            // let change user_id;
            const authResponse: { username: string } = await Auth.currentAuthenticatedUser({ bypassCache: true });
            this.boardData.user_id = authResponse.username;
        } else {
            this.boardId = this.boardData.board_id;
            this.boardName = this.boardData.board_name;
            this.saveFirst = this.data.saveFirst;
        }
    }

    public ngOnDestroy(): void {
        this._active = false;
    }

    public newBoard(): boolean {
        this.boardId = 0;
        this.boardName = '';
        this.boardData.board_id = this.boardId;
        this.boardData.board_name = this.boardName;
        this.saveFirst = false;

        return false;
    }

    public async saveBoard(): Promise<void> {
        let statusMessage: string = '';
        this._loadingSpinner.spin$.next(true);

        // boardName not empty
        if (this.boardName !== '') {
            this.boardData.board_name = this.boardName;
            this.boardData.board_description = '';
            if (this.boardData.display_attributes) {
                this.boardData.display_attribute = this.boardData.display_attributes as string;
            } else {
                this.boardData.display_attribute = '';
            }
            // override board id with the one
            this.boardData.board_id = this.boardId;

            if (!this.timeStatic) {
                this.boardData.time_period = ['recent:All'];
            }

            const time: Array<string> = this.boardData.time_period ? this.boardData.time_period : this.boardData.time_range;

            this.boardData.filter_time = time ? `${time}` : '';
            this.boardData.filter_range = this.boardData.filter_range?.length === 0 ? '': this.boardData.filter_range;

            const filters: string = `${this.boardData.department_name
                .concat(this.boardData.sub_department_name)
                .concat(this.boardData.category_name)
                .concat(this.boardData.product_type_name)
                .join(',')}`;
            this.boardData.filter_product = filters ? `hierarchy_filters=${filters}` : 'hierarchy_filters=';
            this.boardData.board_type = this._view;
            try {
                await firstValueFrom(this._boardService
                    .saveBoard(this.boardData));
                statusMessage = 'This board has been saved successfully';

                this._snackBar.open(statusMessage, 'Close', {
                    duration: 5000
                });

                this.dialogRef.close(this.boardData);
            } catch (e) {
                statusMessage = 'Board save failed';
                this._snackBar.open(statusMessage, 'Close', {
                    duration: 5000
                });
            } finally {
                this._loadingSpinner.spin$.next(false);
            }
        }
    }
}
