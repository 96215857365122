import { Injectable } from '@angular/core';
import { IOptionsApprovalResponseModel } from '../interfaces/options-approval-response.model';
import { IOptionResponseModel } from '../interfaces/option-response.model';
import { IOptionsAttributeValueResponseModel } from '../interfaces/options-attribute-value-response.model';
import { IBudgetResponseModel } from '../interfaces/budget-response.model';
import { ISubTimeOptionActualPercentageRequestModel } from '../interfaces/sub-time-option-actual-percentage-request.model';
import { flatMap } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class MapsCalculationService {
    public getSubTimeOptionActualPercentage(subTimeOptions: Array<ISubTimeOptionActualPercentageRequestModel>): number {
        if (subTimeOptions.length === 0) {
            return 0;
        }

        const summedPercentage: number = subTimeOptions.reduce(
            (acc: number, option: ISubTimeOptionActualPercentageRequestModel) =>
                acc + (option.parentOptionCount > 0 ? option.optionsCount / option.parentOptionCount : 0),
            0
        );

        const nrOptionsWithValues: number = subTimeOptions.filter(
            (option: ISubTimeOptionActualPercentageRequestModel) => option.optionsCount > 0
        ).length;
        const calculatedActualPercentage: number = (summedPercentage / nrOptionsWithValues) * 100;

        return Math.round((calculatedActualPercentage + Number.EPSILON) * 100) / 100;
    }

    public getSubTimeOptionBudget(
        fashionBudget: number,
        subTimeOptions: Array<ISubTimeOptionActualPercentageRequestModel>
    ): number {
        const calculatedBudget: number = (fashionBudget * this.getSubTimeOptionActualPercentage(subTimeOptions)) / 100;
        return Math.round((calculatedBudget + Number.EPSILON) * 100) / 100;
    }

    public calculateOptions(
        attributeValue: string,
        currentApproval: IOptionsApprovalResponseModel,
        lastApproval: IOptionsApprovalResponseModel
    ): IOptionsApprovalResponseModel {
        if (currentApproval && lastApproval) {
            if (attributeValue === 'ALL') {
                this.calculateOptionsForAll(currentApproval, lastApproval);
            } else {
                this.calculateOptionsForSpecificBrand(currentApproval, lastApproval);
            }
        }
        return currentApproval;
    }

    private calculateOptionsForAll(
        currentApproval: IOptionsApprovalResponseModel,
        lastApproval: IOptionsApprovalResponseModel
    ): void {
        const optionsCountTotal: number = flatMap(
            currentApproval.attributeValues,
            (currentAttributeValue: IOptionsAttributeValueResponseModel) => currentAttributeValue.options
        )
            .filter((option: IOptionResponseModel) => option.productLevelId !== 'Total')
            .reduce((sum: number, option: IOptionResponseModel) => sum + option.optionsCount, 0);
        const totalBudget: number = currentApproval.attributeValues.reduce(
            (sum: number, currentAttributeValue: IOptionsAttributeValueResponseModel) => {
                const currentBudget: IBudgetResponseModel = currentAttributeValue.budget;
                return sum + currentBudget.totalBudget * (currentBudget.fashionPercentage / 100);
            },
            0
        );

        currentApproval.attributeValues.forEach((currentAttributeValue: IOptionsAttributeValueResponseModel) => {
            const lastOptions: Array<IOptionResponseModel> = lastApproval.attributeValues.find(
                (t: IOptionsAttributeValueResponseModel) => t.attributeValue === currentAttributeValue.attributeValue
            )?.options;

            if (!lastOptions) {
                throw new Error('Matching AttributeValue not found in lastApproval.');
            }

            // Remove the "Total" row if present, since we're pushing it at the end of this calculation
            const currentOptions: Array<IOptionResponseModel> = currentAttributeValue.options.filter(
                (option: IOptionResponseModel) => option.productLevelId !== 'Total'
            );

            currentOptions.forEach((currentOption: IOptionResponseModel) => {
                const lastOption: IOptionResponseModel = lastOptions.find(
                    (t: IOptionResponseModel) => t.productLevelId === currentOption.productLevelId
                );
                if (!lastOption) {
                    throw new Error('Could not find ' + currentOption.productLevelId);
                }
                const salesMultiplier: number = Math.max(lastOption.minimumOrderQuantity ?? 0, lastOption.averageUnits ?? 0);
                const unitSales: number = currentOption.optionsCount * salesMultiplier;
                currentOption.optionShareOfBusiness =
                    optionsCountTotal === 0 ? 0 : (currentOption.optionsCount / optionsCountTotal) * 100;
                currentOption.unitsSales = currentOption.optionsCount * unitSales;
                currentOption.valueWholesalePriceTotal = unitSales * (lastOption.averageWholesalePrice ?? 0) * 1.1;
            });
        });
        const allCurrentOptions: Array<IOptionResponseModel> = flatMap(
            currentApproval.attributeValues,
            (currentAttributeValue: IOptionsAttributeValueResponseModel) => currentAttributeValue.options
        )
            .filter((option: IOptionResponseModel) => option.productLevelId !== 'Total');
        const unitSalesTotal: number = allCurrentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.unitsSales, 0);
        const valueWspTotal: number = allCurrentOptions.reduce(
            (sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal,
            0
        );
        currentApproval.attributeValues.forEach((currentAttributeValue: IOptionsAttributeValueResponseModel) => {
            const currentOptions: Array<IOptionResponseModel> = currentAttributeValue.options.filter(
                (option: IOptionResponseModel) => option.productLevelId !== 'Total'
            );

            currentOptions.forEach((currentOption: IOptionResponseModel) => {
                currentOption.unitsShareOfBusiness = unitSalesTotal === 0 ? 0 : (currentOption.unitsSales / unitSalesTotal) * 100;
                currentOption.valueShareOfBusiness =
                    valueWspTotal === 0 ? 0 : (currentOption.valueWholesalePriceTotal / valueWspTotal) * 100;
                currentOption.valueBudget =
                    valueWspTotal === 0 ? 0 : (currentOption.valueWholesalePriceTotal / valueWspTotal) * totalBudget;
                currentOption.valueVariance =
                    valueWspTotal === 0
                        ? 0
                        : currentOption.valueWholesalePriceTotal -
                          (currentOption.valueWholesalePriceTotal / valueWspTotal) * totalBudget;
            });
        });

        // const totalRow: IOptionResponseModel = {
        //     optionsCount: optionsCountTotal,
        //     optionShareOfBusiness: allCurrentOptions.reduce(
        //         (sum: number, row: IOptionResponseModel) => sum + row.optionShareOfBusiness,
        //         0
        //     ),
        //     unitsSales: allCurrentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.unitsSales, 0),
        //     valueWholesalePriceTotal: allCurrentOptions.reduce(
        //         (sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal,
        //         0
        //     ),
        //     unitsShareOfBusiness: allCurrentOptions.reduce(
        //         (sum: number, row: IOptionResponseModel) => sum + row.unitsShareOfBusiness,
        //         0
        //     ),
        //     valueShareOfBusiness: allCurrentOptions.reduce(
        //         (sum: number, row: IOptionResponseModel) => sum + row.valueShareOfBusiness,
        //         0
        //     ),
        //     valueBudget: allCurrentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueBudget, 0),
        //     valueVariance:
        //         allCurrentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal, 0) -
        //         allCurrentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueBudget, 0),
        //     productLevelId: 'Total',
        //     productLevelName: '',
        //     subTimeOptions: []
        // };
        //
        // currentOptions.push(totalRow);
    }

    private calculateOptionsForSpecificBrand(
        currentApproval: IOptionsApprovalResponseModel,
        lastApproval: IOptionsApprovalResponseModel
    ): void {
        currentApproval.attributeValues.forEach((currentAttributeValue: IOptionsAttributeValueResponseModel) => {
            const currentBudget: IBudgetResponseModel = currentAttributeValue.budget;
            const totalBudget: number = (currentBudget.totalBudget * currentBudget.fashionPercentage) / 100;

            const lastOptions: Array<IOptionResponseModel> = lastApproval.attributeValues.find(
                (t: IOptionsAttributeValueResponseModel) => t.attributeValue === currentAttributeValue.attributeValue
            )?.options;

            if (!lastOptions) {
                throw new Error('Matching AttributeValue not found in lastApproval.');
            }

            // Remove the "Total" row if present, since we're pushing it at the end of this calculation
            const currentOptions: Array<IOptionResponseModel> = currentAttributeValue.options.filter(
                (option: IOptionResponseModel) => option.productLevelId !== 'Total'
            );
            const optionsCountTotal: number = currentOptions.reduce(
                (sum: number, option: IOptionResponseModel) => sum + option.optionsCount,
                0
            );

            currentOptions.forEach((currentOption: IOptionResponseModel) => {
                const lastOption: IOptionResponseModel = lastOptions.find(
                    (t: IOptionResponseModel) => t.productLevelId === currentOption.productLevelId
                );
                if (!lastOption) {
                    throw new Error('Could not find ' + currentOption.productLevelId);
                }
                const salesMultiplier: number = Math.max(lastOption.minimumOrderQuantity ?? 0, lastOption.averageUnits ?? 0);
                const unitSales: number = currentOption.optionsCount * salesMultiplier;
                currentOption.optionShareOfBusiness =
                    optionsCountTotal === 0 ? 0 : (currentOption.optionsCount / optionsCountTotal) * 100;
                currentOption.unitsSales = currentOption.optionsCount * unitSales;
                currentOption.valueWholesalePriceTotal = unitSales * (lastOption.averageWholesalePrice ?? 0) * 1.1;
            });

            const unitSalesTotal: number = currentOptions.reduce(
                (sum: number, row: IOptionResponseModel) => sum + row.unitsSales,
                0
            );
            const valueWspTotal: number = currentOptions.reduce(
                (sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal,
                0
            );

            currentOptions.forEach((currentOption: IOptionResponseModel) => {
                currentOption.unitsShareOfBusiness = unitSalesTotal === 0 ? 0 : (currentOption.unitsSales / unitSalesTotal) * 100;
                currentOption.valueShareOfBusiness =
                    valueWspTotal === 0 ? 0 : (currentOption.valueWholesalePriceTotal / valueWspTotal) * 100;
                currentOption.valueBudget =
                    valueWspTotal === 0 ? 0 : (currentOption.valueWholesalePriceTotal / valueWspTotal) * totalBudget;
                currentOption.valueVariance =
                    valueWspTotal === 0
                        ? 0
                        : currentOption.valueWholesalePriceTotal -
                          (currentOption.valueWholesalePriceTotal / valueWspTotal) * totalBudget;
            });

            const totalRow: IOptionResponseModel = {
                optionsCount: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.optionsCount, 0),
                optionShareOfBusiness: currentOptions.reduce(
                    (sum: number, row: IOptionResponseModel) => sum + row.optionShareOfBusiness,
                    0
                ),
                unitsSales: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.unitsSales, 0),
                valueWholesalePriceTotal: currentOptions.reduce(
                    (sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal,
                    0
                ),
                unitsShareOfBusiness: currentOptions.reduce(
                    (sum: number, row: IOptionResponseModel) => sum + row.unitsShareOfBusiness,
                    0
                ),
                valueShareOfBusiness: currentOptions.reduce(
                    (sum: number, row: IOptionResponseModel) => sum + row.valueShareOfBusiness,
                    0
                ),
                valueBudget: currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueBudget, 0),
                valueVariance:
                    currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueWholesalePriceTotal, 0) -
                    currentOptions.reduce((sum: number, row: IOptionResponseModel) => sum + row.valueBudget, 0),
                productLevelId: 'Total',
                productLevelName: '',
                subTimeOptions: []
            };

            currentOptions.push(totalRow);
        });
    }
}
