import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { selectAnalyticsActiveView } from '../../core/core.state';
import { DialogDisplayMode } from '../../core/models/dialog-display-mode.enum';
import { Subscription } from 'rxjs';
import { IState } from '../../core/store/store.model';
import { select, Store } from '@ngrx/store';
import { ListingDisplayMode } from '../../core/models/display-mode.enum';

@Component({
    selector: 'lynkd-pattern-tabbed-filter',
    templateUrl: './dialog-tabbed-filter.component.html'
})
export class DialogTabbedFilterComponent implements OnInit {
    public selection: boolean;
    @Input()
    public displayMode: DialogDisplayMode;
    public gallery: ListingDisplayMode = ListingDisplayMode.Gallery;
    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;
    public subscriptions: Subscription = new Subscription();

    @Output()
    public readonly itemSelectionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(private readonly _store: Store<IState>) {
        this.selection = true;
    }

    public ngOnInit(): void {
        this.subscriptions.add(
            this._store.pipe(select(selectAnalyticsActiveView),
                distinctUntilChanged((previous: ListingDisplayMode, current: ListingDisplayMode) => previous === current))
                .subscribe((result: ListingDisplayMode) => {
                    this.gallery = result;
                    this.selection = this.gallery !== ListingDisplayMode.Attributes;
                })
        );
    }

    public selectionChange(event: boolean): void {
        this.selection = event;
        this.itemSelectionChange.emit(event);
    }
}
