<nz-table
    #basicTable
    [nzData]="data"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzBordered]="false"
    [nzLoading]="loading"
>
    <thead class="table-header th-sticky sortable text-center">
    <tr>
        <th [nzAlign]="'center'" *ngIf="selectedAttributeValue === 'ALL'"></th>
        <th [nzAlign]="'center'"></th>
        <th [nzAlign]="'center'" [colspan]="group.columns.length" *ngFor="let group of columnGroups">
            {{ group.name | uppercase }}
        </th>
    </tr>
    <tr>
        <th [nzAlign]="'center'" *ngIf="selectedAttributeValue === 'ALL'"></th>
        <th [nzAlign]="'center'" *ngFor="let column of columns">{{ column.label | titlecase }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="basicTable?.data?.length > 0">
        <td [nzAlign]="'center'" class="custom-footer" *ngIf="selectedAttributeValue === 'ALL'">Total</td>
        <td [nzAlign]="'center'" class="custom-footer"></td>
        <td [nzAlign]="'center'" *ngFor="let col of columns | slice : 1" class="custom-footer">
            <ng-container [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="'decimal'">
                    {{ getColumnTotal(col.name) | thousandSeparator }}
                </ng-container>

                <ng-container *ngSwitchCase="'percentage'">
                    {{ getColumnTotal(col.name) | shortNumber | percentValue }}
                </ng-container>

                <ng-container *ngSwitchCase="'text'">
                    {{ getColumnTotal(col.name) }}
                </ng-container>
            </ng-container>
        </td>
    </tr>
    <tr *ngFor="let data of basicTable.data" class="editable-row">
        <td [nzAlign]="'center'" *ngIf="selectedAttributeValue === 'ALL'">
            {{ data.attribute_value }}
        </td>
        <td [nzAlign]="'center'" *ngFor="let col of columns; index as i">
            <ng-container *ngIf="i === 0">
                {{ getShortId(data.product_level_value) }}
            </ng-container>

            <ng-container *ngIf="i !== 0">
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'decimal'">
                        {{ data[col.name] | thousandSeparator }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'percentage'">
                        {{ data[col.name] | percentValue }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'text'">
                        {{ data[col.name] }}
                    </ng-container>
                </ng-container>
            </ng-container>
        </td>
    </tr>
    </tbody>
</nz-table>
