<nz-table
    #basicTable
    [nzData]="data"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzBordered]="false"
    [nzLoading]="loading"
>
    <thead class="table-header th-sticky sortable text-center">
        <tr>
            <th [nzAlign]="'center'" [colspan]="group.columns.length" *ngFor="let group of columnGroups">
                {{ group.name | uppercase }}
            </th>
        </tr>
        <tr>
            <th [nzAlign]="'center'" *ngFor="let column of columns">{{ column.label | titlecase }}</th>
        </tr>
    </thead>
    <tbody>
        <tr class="custom-footer-row">
            <td [nzAlign]="'center'" class="custom-footer" *ngIf="!totals">Totals</td>
            <td [nzAlign]="'center'" class="custom-footer" *ngIf="totals">{{ getColumnTotal('sku_plan') }}</td>
            <td [nzAlign]="'center'" *ngFor="let col of columns | slice : 1" class="custom-footer">
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'decimal'">
                        {{ getColumnTotal(col.name) | shortNumber }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'percentage'">
                        {{ getColumnTotal(col.name) | percentValue }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'text'">
                        {{ getColumnTotal(col.name) }}
                    </ng-container>
                </ng-container>
            </td>
        </tr>
        <tr *ngFor="let data of basicTable.data; index as i" class="editable-row" (click)="startEdit(i)">
            <td class="dynamic-column" [nzAlign]="'center'">
                <div class="editable-cell" *ngIf="editId !== i">
                    {{ data.sku_plan }}
                </div>
                <input
                    type="text"
                    lynkdPatternAutoFocus
                    nz-input
                    *ngIf="editId === i"
                    [(ngModel)]="data.sku_plan"
                    (keydown.enter)="stopEdit(i, data.sku_plan)"
                    (blur)="stopEdit(i, data.sku_plan)"
                />
            </td>
            <td [nzAlign]="'center'" *ngFor="let col of columns | slice : 1; index as i">
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'decimal'">
                        {{ data[col.name] | shortNumber }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'percentage'">
                        {{ data[col.name] | percentValue }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'text'">
                        {{ data[col.name] }}
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </tbody>
</nz-table>
