import packageJson from '../../../../package.json';
import { AllocationMethod } from '../app/shared/models/allocation-method.enum';
import { DefaultMetricsGroup } from '../app/shared/models/default-metrics-group.enum';


export const environment: {
    appName: string;
    test: boolean;
    debug: boolean;
    apiUrl: string;
    production: boolean;
    attributes: boolean;
    listingPagination: boolean;
    envName: string;
    version: string;
    userManagementApiUrl: string;
    pictureManagerApiUrl: string;
    awsCognitoIdentityPoolId: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
    pictureManagerCloudfrontDistribution: string;
    packlotEnabled: boolean;
    /**
     * Description for stylingMetricsSplit
     *
     * Default: false
     * BRD: true
     *
     * When stylingMetricsSplit is true, the getSizeValue function, in the styling component, splits the string
     * and returns the number.
     * Associated code: return Number(String(item[size]).split('|')[0]);
     *
     * When stylingMetricsSplit is false, the getSizeValue function, in the styling component, just returns the item size.
     * Associated code: return item[size];
     */
    stylingMetricsSplit: boolean;

    /**
     * Description for stylingExcludeStoreNumber
     *
     * Default: false
     * BRD: true
     *
     * When stylingExcludeStoreNumber is set to true, the getStoreName function, in the styling component, returns the
     * store name WITHOUT the brackets and id.
     * Associated code: return item.store_name.substr(0, item.store_name.indexOf('(')).trim();
     *
     * When stylingExcludeStoreNumber is set to false, the getStoreName function, in the styling component, returns the
     * store name WITH the brackets and id.
     * Associated code: return item.store_name.substr(item.store_name.indexOf(')') + 2).trim();
     */
    stylingExcludeStoreNumber: boolean;

    /**
     * Description for allocationMethod
     *
     * Duns: ExactMl
     * BRD: ExactAlgo
     * Default: ExactAlgo
     *
     * This dictates the allocation method used in the determineAllocationColumn, in the workbench table component.
     * Associated code: return `allocated_${environment.allocationMethod}_${data.weeks_of_cover}wk_sls_u`;
     *                  return `allocated_${environment.allocationMethod}_${parameters.weeksOfCover}wk_sls_u`;
     *                  return 'allocated_${environment.allocationMethod}_${environment.allocationMethodNumberOfWeeks}wk_sls_u';
     */
    allocationMethod: AllocationMethod;

    /**
     * Description for allocationDefaultNumberOfWeeks
     *
     * Default: 8
     *
     * This dictates the number of weeks for the allocation method used in the determineAllocationColumn, in the workbench table component.
     * Associated code: return 'allocated_${environment.allocationMethod}_${environment.allocationMethodNumberOfWeeks}wk_sls_u';
     */
    allocationDefaultNumberOfWeeks: number;
    defaultMetricsGroup: DefaultMetricsGroup;
    canCreateAllocations: boolean;
    nextFrontendUrl: string;
    expansiveWeeksOfCover: boolean;
    allocationFlushOutNumberOfWeeks: number;
    allocationHasFlushOut: boolean;
    onlyHasAllocations: boolean;
    useBrandivioBranding: boolean;
} = {
    appName: "Lynkd Pattern Analytics",
    envName: 'PROD',
    debug: false,
    production: true,
    test: false,
    attributes: true,
    listingPagination: false,
    version: packageJson.version,
    apiUrl: 'https://spcc-prod-wl-api.patternretail.com',
    userManagementApiUrl: 'https://ak6eb84fed.execute-api.af-south-1.amazonaws.com/prod',
    pictureManagerApiUrl: '',
    awsCognitoIdentityPoolId: 'eu-west-1:432775cd-5a88-4470-ac23-d8f0668eee3e',
    awsUserPoolsId: 'eu-west-1_UTCYGYgKz',
    awsUserPoolsWebClientId: '4t1g601nsvq06qmbn5bikagovj',
    pictureManagerCloudfrontDistribution: '',
    stylingMetricsSplit: false,
    stylingExcludeStoreNumber: false,
    allocationMethod: AllocationMethod.ExactAlgo,
    allocationDefaultNumberOfWeeks: 0,
    packlotEnabled: false,
    defaultMetricsGroup: DefaultMetricsGroup.Algo,
    canCreateAllocations: true,
    nextFrontendUrl: '',
    expansiveWeeksOfCover: false,
    allocationFlushOutNumberOfWeeks: 0,
    allocationHasFlushOut: false,
    onlyHasAllocations: false,
    useBrandivioBranding: false
};
