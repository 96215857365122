import { Component, OnInit } from '@angular/core';
import { IOptionsRowCalculationResponse } from '../../../../core/models/options-row-calculation-response.model';
import { MapsService } from '../../services/maps.service';
import { ILYTable } from '../../../../core/models/ly-table.model';
import { IPreviousOptionsResponse } from '../../../../core/models/previous-options-response.model';
import { IBudgetOptionView } from '../../../../core/models/budget-options-view.model';
import { IOption as TyBudget, IOptionsTimeValueSaveRequest, ITYTable, IOption } from '../../../../core/models/ty-table.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../core/core.state';
import { selectUserDetails } from '../../../../core/store/store.selectors';
import { IUserRole } from '../../../../core/models/user-roles.model';
import { IBudgetCalculateTotalResponse } from '../../../../core/models/budget-calculate-total-response.model';
import { IBudgetSaveRequest } from '../../../../core/models/budget-save-request.model';
import { LoadingSpinnerService } from '../../../../core/data/loading-spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { IModelStateError, IModelStateErrorResponse } from '../../../../core/models/error-response.model';
import { firstValueFrom } from 'rxjs';
import { IMonthlyOptionReport } from '../../interfaces/monthly-option-report.interface';

@Component({
    selector: 'lynkd-pattern-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
    public selectedView: string = 'options';

    public lyMockData: ILYTable;
    public tyMockData: ITYTable;

    public data: IBudgetOptionView;
    public availableAttributes: Array<string> = [];
    public availableTimeValues: Array<string>;
    public selectedTimeValue: string;
    public selectedAttribute: string;
    public selectedAttributeView: IBudgetOptionView;
    public activeViewContext: string;
    public optionsTableData: IBudgetCalculateTotalResponse;
    public monthlyOptionsData: IMonthlyOptionReport;
    public tyTableState: Array<IOption>;
    public loading: boolean = false;

    public constructor(
        private readonly _mapsService: MapsService,
        private readonly _store: Store<IAppState>,
        private readonly _snackbarService: MatSnackBar,
        private readonly _spinnerService: LoadingSpinnerService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.availableAttributes = await this._mapsService.getAttributeValues();
        this.availableTimeValues = await this._mapsService.getTimeValues();

        try {
            const allRoles: { email: string; roles: Array<IUserRole> } = await firstValueFrom(
                this._store.select(selectUserDetails)
            );
            const mapsRoles: IUserRole = allRoles?.roles.find((role: IUserRole) => role.module === 'maps');

            if (mapsRoles?.roles.includes('manager')) {
                this.activeViewContext = 'manager';
            } else if (mapsRoles?.roles.includes('planner')) {
                this.activeViewContext = 'planner';
            }

            this.lyMockData = await this._mapsService.getLYMockData();
            this.tyMockData = await this._mapsService.getTYMockData();
        } catch (error) {
            this._snackbarService.open('An error occurred', 'close', {
                duration: 1500
            });
        }
    }

    public async getLastYearBudget(timeValue: string, attributeValue: string): Promise<void> {
        const res: Array<IPreviousOptionsResponse> = await this._mapsService.getPreviousOptions(timeValue, attributeValue);
        const tyRes: Array<IOptionsRowCalculationResponse> = await this._mapsService.getAllOptions(timeValue, attributeValue);

        const filteredRes: Array<IPreviousOptionsResponse> = res.filter(
            (val: IPreviousOptionsResponse) => !['total', 'grand total'].includes(val.product_level_value.toLowerCase())
        );

        let tyData: Array<TyBudget>;
        let tyTotal: TyBudget;
        if (tyRes?.length && tyRes.filter((t: IOptionsRowCalculationResponse) => t.product_level_value !== 'Total').length) {
            const originalTy: Array<IOptionsRowCalculationResponse> = tyRes.filter(
                (t: IOptionsRowCalculationResponse) => t.product_level_value !== 'Total'
            );

            const metricsInList: Array<string> = originalTy.map((t: IOptionsRowCalculationResponse) => t.product_level_value);
            tyData = originalTy.concat(
                filteredRes
                    .filter((t: IPreviousOptionsResponse) => !metricsInList.includes(t.product_level_value))
                    .map((t: IPreviousOptionsResponse) => ({
                        attribute_value: attributeValue,
                        product_level_value: t.product_level_value,
                        sku_plan: 0,
                        op_sob: 0,
                        units_sales: 0,
                        units_sob: 0,
                        value_wsp_total: 0,
                        value_sob: 0,
                        value_budget: 0,
                        value_var: 0
                    }))
            );
            tyTotal = tyRes.find((t: IOptionsRowCalculationResponse) => t.product_level_value === 'Total');
        } else {
            tyData = filteredRes.map((t: IPreviousOptionsResponse) => ({
                attribute_value: attributeValue,
                product_level_value: t.product_level_value,
                sku_plan: 0,
                op_sob: 0,
                units_sales: 0,
                units_sob: 0,
                value_wsp_total: 0,
                value_sob: 0,
                value_budget: 0,
                value_var: 0
            }));
            tyTotal = {
                attribute_value: attributeValue,
                product_level_value: 'Total',
                sku_plan: 0,
                op_sob: 0,
                units_sales: 0,
                units_sob: 0,
                value_wsp_total: 0,
                value_sob: 0,
                value_budget: 0,
                value_var: 0
            };
        }
        this.selectedAttributeView = {
            attribute_value: attributeValue,
            ly_table_data: {
                columnGroups: this.lyMockData.columnGroups,
                columns: this.lyMockData.columns,
                data: filteredRes,
                totals: res.find((val: IPreviousOptionsResponse) => val.product_level_value.toLowerCase() === 'total')
            },
            ty_table_data: {
                columnGroups: this.tyMockData.columnGroups,
                columns: this.tyMockData.columns,
                data: tyData,
                total: tyTotal
            }
        };
    }

    public async getAllMonthlyOptions(attributeValue: string, timeValue: string): Promise<void> {
        this.monthlyOptionsData = await this._mapsService.getAllMonthlyOptions(attributeValue, timeValue);
    }

    public async filterChanged(): Promise<void> {
        if (this.selectedTimeValue && this.selectedAttribute) {
            this.loading = true;
            try {
                await this.getLastYearBudget(this.selectedTimeValue, this.selectedAttribute);
                await this.getAllMonthlyOptions(this.selectedAttribute, this.selectedTimeValue);
                this.loading = false;
            } catch (error) {
                //TODO add error logging
                this.loading = false;
            }
        }
    }

    public async viewChanged(evt: MatButtonToggleChange): Promise<void> {
        if (evt.value === 'options') {
            if (this.selectedTimeValue && this.selectedAttribute) {
                this.loading = true;
                await this.getLastYearBudget(this.selectedTimeValue, this.selectedAttribute);
                this.loading = false;
                return;
            }
        }
    }

    public getOptionsTableState($evt: IBudgetCalculateTotalResponse): void {
        this.optionsTableData = $evt;
    }

    public saveOptionsTable(): void {
        if (!(this.selectedTimeValue && this.selectedAttribute)) {
            this._snackbarService.open('Please select a season and an attribute', 'close');
            return;
        }

        this.loading = true;
        const payload: IBudgetSaveRequest = {
            core_percentage: this.optionsTableData.core_percentage,
            fashion_percentage: this.optionsTableData.fashion_percentage,
            total_budget: this.optionsTableData.total_budget,
            attribute_value: this.selectedAttributeView.attribute_value,
            time_value: this.selectedTimeValue
        };
        this._mapsService.saveBudget(payload).subscribe({
            next: () => {
                this.loading = false;
                this._snackbarService.open('Budget Totals saved', 'close');
            },
            error: (error: IModelStateErrorResponse) => {
                error.errors.forEach((err: IModelStateError) => {
                    this._snackbarService.open(err.value[0], 'close');
                });
            }
        });
    }

    public async approve(): Promise<void> {
        if (!(this.selectedTimeValue && this.selectedAttribute)) {
            this._snackbarService.open('Please select a season and an attribute', 'close');
            return;
        }

        try {
            this.loading = true;
            await this._mapsService.approveOptions({
                time_value: this.selectedTimeValue,
                attribute_value: this.selectedAttribute
            });
            this._snackbarService.open('Budget approved', 'close');
            this.loading = false;
        } catch (err) {
            this._snackbarService.open('An error occurred', 'close');
        }
    }

    public async save(): Promise<void> {
        if (this.selectedAttributeView?.ty_table_data.data.filter((val: TyBudget) => val.sku_plan === 0).length > 0) {
            this._snackbarService.open('Please fill all required inputs', 'close');
            return;
        }

        if (!(this.selectedTimeValue && this.selectedAttribute)) {
            this._snackbarService.open('Please select a time value and an attribute value', 'close');
            return;
        }

        this.loading = true;

        try {
            await this._mapsService.saveOptions(
                this.selectedAttributeView.ty_table_data.data
                    .filter((t: TyBudget) => t.product_level_value !== 'Total')
                    .map(
                        (t: TyBudget) =>
                            ({
                                ...t,
                                time_value: this.selectedTimeValue
                            } as IOptionsTimeValueSaveRequest)
                    )
            );

            this._snackbarService.open('Budget saved', 'close');
        } catch (error) {
            this._snackbarService.open(error, 'close');
        }
        this.loading = false;
    }

    public getTableState(state: Array<IOption>): void {
        this.tyTableState = state;
    }
}
