import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOptionsRowCalculationResponse } from '../../../../core/models/options-row-calculation-response.model';
import { IOption, IOptionsTimeValueSaveRequest, IColumn, IColumnGroup } from '../../../../core/models/ty-table.model';
import { MapsService } from '../../services/maps.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'lynkd-pattern-ty-plan-table',
    templateUrl: './ty-plan-table.component.html',
    styleUrls: ['./ty-plan-table.component.scss']
})
export class TyPlanTableComponent {
    @Input()
    public data: Array<IOption>;

    public editId: number | null = null;

    @Input()
    public set loading(data: boolean) {
        if (data) {
            this._loading = data;
            return;
        }

        this._loading = false;
    }

    public get loading(): boolean {
        return this._loading;
    }

    @Input()
    public set columns(data: Array<IColumn>) {
        if (data !== null && data !== undefined) {
            this._columns = data.sort((a: IColumn, b: IColumn) => a.order - b.order);
        }
    }

    public get columns(): Array<IColumn> {
        return this._columns;
    }

    @Input()
    public set columnGroups(data: Array<IColumnGroup>) {
        if (data !== null && data !== undefined) {
            this._columnGroups = data.sort((a: IColumnGroup, b: IColumnGroup) => a.order - b.order);
        }
    }

    public get columnGroups(): Array<IColumnGroup> {
        return this._columnGroups;
    }

    @Input()
    public selectedSeason: string;

    @Input()
    public selectedAttributeValue: string;

    @Input()
    public totals: IOption;

    @Output()
    public readonly state: EventEmitter<Array<IOption>> = new EventEmitter();

    private _columns: Array<IColumn>;
    private _columnGroups: Array<IColumnGroup>;
    private _loading: boolean;

    public constructor(public readonly _mapsService: MapsService, private readonly _snackbar: MatSnackBar) {}

    public startEdit(dataId: number): void {
        this.editId = dataId;
    }

    public async stopEdit(index: number, sku_plan: number): Promise<void> {
        if (!sku_plan) {
            this.editId = null;
        } else {
            this.editId = null;
            this.loading = true;
            this.state.emit(this.data);

            try {
                const rows: Array<IOptionsRowCalculationResponse> = await this._mapsService.calculateOptionsRows(
                    this.data.map(
                        (t: IOption) =>
                            ({
                                ...t,
                                time_value: this.selectedSeason
                            } as IOptionsTimeValueSaveRequest)
                    )
                );
                this.data = rows.filter((t: IOption) => t.product_level_value !== 'Total');
                this.totals = rows.find((t: IOption) => t.product_level_value === 'Total');
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this._snackbar.open('an error occurred', 'close', {
                    duration: 1000
                });
            }
        }
    }

    public getColumnTotal(columnName: string): number {
        return this.totals ? this.totals[columnName] : 0;
    }
}
