import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilterData } from '../../../core/models/filter-data.model';

@Component({
    selector: 'lynkd-pattern-application-breadcrumb',
    templateUrl: './application-breadcrumb.component.html',
    styleUrls: ['./application-breadcrumb.component.scss']
})
export class ApplicationBreadcrumbComponent {
    @Input()
    public buttonLabel: string;
    @Input()
    public mainFilterName: 'location' | 'time' | keyof IFilterData;
    @Input()
    public filterName: keyof IFilterData;
    @Input()
    public metaDataName: string;
    @Input()
    public type: 'Singular' | 'Multiple';
    @Input()
    public filterValue: string | Array<string>;
    @Output()
    public readonly mainFilterRemoved: EventEmitter<{ filter: 'location' | 'time' | keyof IFilterData; filterItem?: string }> =
        new EventEmitter<{ filter: 'location' | 'time' | keyof IFilterData; filterItem?: string }>();
    @Output()
    public readonly filterRemoved: EventEmitter<{
        filter: keyof IFilterData;
        filterItem: string;
        subFilter?: string;
    }> = new EventEmitter<{
        filter: keyof IFilterData;
        filterItem: string;
        subFilter?: string;
    }>();

    public removeMainFilter(): void {
        this.mainFilterRemoved.emit({ filter: this.filterName, filterItem: this.metaDataName });
    }

    public removeFilter(valueToRemove: string): void {
        this.filterRemoved.emit({ filter: this.filterName, filterItem: valueToRemove, subFilter: this.metaDataName });
    }
}
