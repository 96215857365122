<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" [ngClass]="styling.wrapper()">
    <path *ngIf="variant === 'left'" d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
    <path *ngIf="variant === 'forward'" d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
    <path *ngIf="variant === 'add'" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
    <path *ngIf="variant === 'down'" d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
    <path *ngIf="variant === 'check'" d="M382-208 122-468l90-90 170 170 366-366 90 90-456 456Z" />
    <path *ngIf="variant === 'right'" d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />

    <path
        *ngIf="variant === 'expand'"
        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z"
    />

    <path
        *ngIf="variant === 'search'"
        d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
    />
</svg>
