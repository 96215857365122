import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom} from 'rxjs';
import { AppConfigService } from '../../core/data/app-config.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductHierarchyService {
    private readonly apiUrl: string = this._appConfigService.apiUrl;
    private readonly baseUrl: string = environment.onlyHasAllocations ? '/api/allocation/producthierarchy' : '/api/producthierarchy';
    private departments: Array<string>;
    private subDepartments: Array<string>;
    private categories: Array<string>;
    private productTypes: Array<string>;
    private styleNames: Array<string>;

    public constructor(private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService) {
    }

    public async getProductHierarchy(level: string): Promise<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}/${level}`);

        return firstValueFrom(this._http.get<Array<string>>(query));
    }

    public async getProductHierarchyDepartments(): Promise<Array<string>> {
        if (this.departments){
            return this.departments;
        }
        const level: string = "department_name";
        this.departments = await this.getProductHierarchy(level);
        return this.departments;
    }

    public async getProductHierarchySubDepartments(): Promise<Array<string>> {
        if (this.subDepartments){
            return this.subDepartments;
        }
        const level: string = "sub_department_name";
        this.subDepartments = await this.getProductHierarchy(level);
        return this.subDepartments;
    }

    public async getProductHierarchyCategories(): Promise<Array<string>> {
        if (this.categories){
            return this.categories;
        }
        const level: string = "category_name";
        this.categories = await this.getProductHierarchy(level);
        return this.categories;
    }

    public async getProductHierarchyProductTypes(): Promise<Array<string>> {
        if (this.productTypes){
            return this.productTypes;
        }
        const level: string = "product_type_name";
        this.productTypes = await this.getProductHierarchy(level);
        return this.productTypes;
    }

    public async getProductHierarchyStyles(): Promise<Array<string>> {
        if (this.styleNames){
            return this.styleNames;
        }
        const level: string = "style_name";
        this.styleNames = await this.getProductHierarchy(level);
        return this.styleNames;
    }
}
