<div
    *ngIf="isAuthenticated$ | async"
    [class.opened]="true"
    class="row align-items-left align-items-md-center justify-content-between dashboard-toolbar"
>
    <div class="col-auto">
        <a href="/" class="no-margin">
            <img [src]="useBrandivioBranding ? 'assets/navigation-logo-brandivio.svg' : 'assets/navigation-logo.svg'" class="nav-logo" alt="Logo" />
        </a>
    </div>
    <div class="col-auto justify-content-center text-white">
        <h3 class="module-name">{{ application?.label }}</h3>
    </div>
    <div class="col-auto">
        <lynkd-pattern-allocations-views *ngIf="application?.name === 'allocations'"></lynkd-pattern-allocations-views>
        <lynkd-pattern-analytics-views *ngIf="application?.name === 'analytics'"></lynkd-pattern-analytics-views>
        <lynkd-pattern-user-manager-views *ngIf="application?.name === 'users'"></lynkd-pattern-user-manager-views>
        <lynkd-pattern-picture-manager-views
            *ngIf="application?.name === 'picture-manager'"
        ></lynkd-pattern-picture-manager-views>
        <lynkd-pattern-maps-views *ngIf="application?.name === 'maps'"></lynkd-pattern-maps-views>
    </div>
    <div class="col align-right">
        <button (click)="logout()" *ngIf="isAuthenticated$ | async" color="accent" mat-flat-button>
            <img src="assets/icon-logout.svg" alt="" />
            Logout
        </button>
    </div>
</div>
