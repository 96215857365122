<div class="view-selection-wrapper">
    <div class="view-selection-button-group-container">
        <mat-button-toggle-group aria-label="View selection" [(ngModel)]="selectedView" (change)="viewChanged($event)">
            <mat-button-toggle class="font-weight-bold" [value]="'budget'">Budget</mat-button-toggle>
            <mat-button-toggle class="font-weight-bold" [value]="'options'" [checked]="true">Options</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>

<div class="layout-card">
    <div [class]="selectedView === 'options' ? ('table-container') : ('hide')">
        <div class="header">
            <div class="d-flex flex-row justify-content-start">
                <p class="font-weight-bold my-2">{{ selectedTimeValue }} ASSORTMENT MAP - SKU, UNITS AND VALUE</p>
            </div>

            <div class="d-flex justify-content-start align-items-center drop-downs">
                <div>
                    <div>
                        <label>Department:</label>
                    </div>
                    <nz-select
                        class="dropdown-selector"
                        nzPlaceHolder="Select department"
                        nzAllowClear="true"
                        [(ngModel)]="selectedDepartmentId"
                        (ngModelChange)="departmentChanged()"
                    >
                        <nz-option [nzValue]="department.id" [nzLabel]="getShortId(department.id)"
                                   *ngFor="let department of availableDepartmentValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Sub-Department:</label>
                    </div>
                    <nz-select
                        class="dropdown-selector"
                        nzPlaceHolder="Select sub department"
                        nzAllowClear="true"
                        [(ngModel)]="selectedSubDepartmentId"
                        (ngModelChange)="subDepartmentChanged()"
                    >
                        <nz-option [nzValue]="subDepartment.id" [nzLabel]="getShortId(subDepartment.id)"
                                   *ngFor="let subDepartment of filteredSubDepartmentValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Category:</label>
                    </div>
                    <nz-select
                        class="dropdown-selector"
                        nzPlaceHolder="Select category"
                        nzAllowClear="true"
                        [(ngModel)]="selectedCategoryId"
                        (ngModelChange)="categoryChanged()"
                    >
                        <nz-option [nzValue]="category.id" [nzLabel]="getShortId(category.id)"
                                   *ngFor="let category of filteredCategoryValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Season:</label>
                    </div>
                    <nz-select
                        nzPlaceHolder="Select season"
                        nzAllowClear="true"
                        [(ngModel)]="selectedTimeValue"
                        class="dropdown-selector"
                        (ngModelChange)="filterChanged()"
                    >
                        <nz-option [nzValue]="timeValue" [nzLabel]="timeValue"
                                   *ngFor="let timeValue of availableTimeValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Brand:</label>
                    </div>
                    <nz-select
                        nzPlaceHolder="Select attribute"
                        nzAllowClear="true"
                        [(ngModel)]="selectedAttribute"
                        class="dropdown-selector"
                        (ngModelChange)="filterChanged()"
                    >
                        <nz-option [nzValue]="value" [nzLabel]="value"
                                   *ngFor="let value of availableAttributes"></nz-option>
                    </nz-select>
                </div>
                <div class="mr-1">
                    <div>
                        <label></label>
                    </div>
                    <button class="approve-btn" *ngIf="hasRole('planner')" (click)="save()">Save</button>
                </div>

                <div>
                    <div>
                        <label></label>
                    </div>
                    <button class="approve-btn" *ngIf="hasRole('manager')" (click)="approve()">Approve</button>
                </div>

            </div>
        </div>

        <div class="collapse-container">
            <nz-collapse nzAccordion [nzBordered]="false">
                <nz-collapse-panel [nzHeader]="'Yearly Options Table'" [nzActive]="true">
                    <div class="wrapper">
                        <div class="brand-name">
                            <p>{{ selectedAttributeView?.attribute_value }}</p>
                        </div>
                        <div class="content">
                            <div class="ly-wrapper">
                                <div class="type-label">LY</div>
                                <lynkd-pattern-ly-plan-table
                                    [selectedAttributeValue]="selectedAttribute"
                                    [columnGroups]="selectedAttributeView?.ly_table_data.columnGroups"
                                    [columns]="selectedAttributeView?.ly_table_data.columns"
                                    [data]="selectedAttributeView?.ly_table_data.filtered_data"
                                    [totals]="selectedAttributeView?.ly_table_data.totals"
                                    [loading]="loading"
                                ></lynkd-pattern-ly-plan-table>
                            </div>
                            <div class="ly-wrapper">
                                <div class="type-label">TY</div>
                                <lynkd-pattern-ty-plan-table
                                    [columnGroups]="selectedAttributeView?.ty_table_data.columnGroups"
                                    [columns]="selectedAttributeView?.ty_table_data.columns"
                                    [data]="selectedAttributeView?.ty_table_data.filtered_data"
                                    [selectedSeason]="selectedTimeValue"
                                    [totals]="selectedAttributeView?.ty_table_data.total"
                                    [selectedAttributeValue]="selectedAttribute"
                                    [loading]="loading"
                                    (state)="getTableState($event)"
                                ></lynkd-pattern-ty-plan-table>
                            </div>
                        </div>
                    </div>
                </nz-collapse-panel>
                <nz-collapse-panel [nzHeader]="'Monthly Options Table'">
                    <lynkd-pattern-options-monthly-table
                        [attributeValue]="selectedAttributeView?.attribute_value"
                        [timeValue]="selectedTimeValue"
                        [data]="monthlyOptionsData"
                        [loading]="loading"
                        (updateTyOptionsApproval)="handleMonthlyOptionsSkuUpdate($event)"
                    ></lynkd-pattern-options-monthly-table>
                </nz-collapse-panel>
            </nz-collapse>
        </div>
    </div>

    <div [class]="selectedView === 'budget' ? ('table-container') : ('hide')">
        <div class="header">
            <div class="d-flex flex-row justify-content-start">
                <p class="font-weight-bold my-2"></p>
            </div>
            <div class="d-flex justify-content-start align-items-center drop-downs">
                <div>
                    <div>
                        <label>Department:</label>
                    </div>
                    <nz-select
                        class="dropdown-selector"
                        nzPlaceHolder="Select department"
                        nzAllowClear="true"
                        [(ngModel)]="selectedDepartmentId"
                        (ngModelChange)="departmentChanged()"
                    >
                        <nz-option [nzValue]="department.id" [nzLabel]="getShortId(department.id)"
                                   *ngFor="let department of availableDepartmentValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Sub-Department:</label>
                    </div>
                    <nz-select
                        class="dropdown-selector"
                        nzPlaceHolder="Select sub department"
                        nzAllowClear="true"
                        [(ngModel)]="selectedSubDepartmentId"
                        (ngModelChange)="subDepartmentChanged()"
                    >
                        <nz-option [nzValue]="subDepartment.id" [nzLabel]="getShortId(subDepartment.id)"
                                   *ngFor="let subDepartment of filteredSubDepartmentValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Season:</label>
                    </div>
                    <nz-select
                        nzPlaceHolder="Select season"
                        nzAllowClear="true"
                        [(ngModel)]="selectedTimeValue"
                        class="dropdown-selector"
                        (ngModelChange)="filterChanged()"
                    >
                        <nz-option [nzValue]="timeValue" [nzLabel]="timeValue"
                                   *ngFor="let timeValue of availableTimeValues"></nz-option>
                    </nz-select>
                </div>
                <div>
                    <div>
                        <label>Brand:</label>
                    </div>
                    <nz-select
                        nzPlaceHolder="Select attribute"
                        nzAllowClear="true"
                        [(ngModel)]="selectedAttribute"
                        class="dropdown-selector"
                        (ngModelChange)="filterChanged()"
                    >
                        <nz-option [nzValue]="value" [nzLabel]="value"
                                   *ngFor="let value of availableAttributes"></nz-option>
                    </nz-select>
                </div>
                <div class="mr-1">
                    <div>
                        <label></label>
                    </div>
                    <button class="approve-btn" *ngIf="hasRole('planner')" (click)="save()">Save</button>
                </div>

                <div>
                    <div>
                        <label></label>
                    </div>
                    <button class="approve-btn" *ngIf="hasRole('manager')" (click)="approve()">Approve</button>
                </div>

            </div>
        </div>
        <div>
            <lynkd-pattern-totals-table
                [tYOptionsApprovalData]="tYOptionsApprovalData"
                [timeValue]="selectedTimeValue"
                [attributeValue]="selectedAttribute"
                [availableAttributes]="availableAttributes"
                [loading]="loading"
                (state)="handleOptionsTableUpdate($event)"
            >
            </lynkd-pattern-totals-table>
        </div>
    </div>
</div>
