<button
    mat-button
    [matMenuTriggerFor]="menu">
    <img src='assets/icon-filter.svg' alt='filter' width="18px" height='18' />
    <div class="d-inline-block">{{buttonLabel}}</div>
    <div class="d-inline-block" (click)="removeMainFilter()">
        <img
            src="assets/icon-close-alt2.svg"
            alt="close button"
            width="8px"
            height="auto"
            class="remove-icon"
        />
    </div>
</button>
<mat-menu #menu="matMenu" yPosition="below">
    <label>{{chipLabel}</label>
    <mat-chip-list>
        <ng-container *ngIf="type === 'Singular'">
            <mat-chip
                [removable]="true"
                (removed)="removeFilter(filterValue)"
                class="text-capitalize">
                {{ filterValue }}
                <img
                    src='assets/icon-close-alt.svg'
                    width="14"
                    height='14'
                    alt="close button"
                    matChipRemove
                />
            </mat-chip>
        </ng-container>
        <ng-container *ngIf="type === 'Multiple'">
            <mat-chip
                *ngFor="let value of filterValue"
                [removable]="true"
                (removed)="removeFilter(value)"
                class="text-capitalize"
            >
                {{ value | titlecase }}
                <img
                    src='assets/icon-close-alt.svg'
                    width="14"
                    height='14'
                    alt="close button"
                    matChipRemove
                />
            </mat-chip>
        </ng-container>
    </mat-chip-list>
</mat-menu>
