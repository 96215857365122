import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
    public transform(value: string, maxLength: number): string {
        if (value.length <= maxLength) {
            return value;
        }
        return value.substring(0, maxLength) + '...';
    }
}
